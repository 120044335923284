// ** Toolkit imports
import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import storage from "redux-persist/lib/storage"
import { persistStore, persistReducer } from "redux-persist"

// ** Reducers
import app from './apps/app'
import numpad from './apps/numpad'
import faqs from './apps/faqs'
import delivery from './apps/delivery'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: []
}

const rootReducer = combineReducers({
  app,
  numpad,
  faqs,
  delivery
})

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
})

const persistor = persistStore(store);

export { store, persistor }