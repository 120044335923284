import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useDispatch, useSelector } from 'react-redux';
import { updateSocketStatus } from 'store/apps/app';
import { appSettings } from 'config/app';
import cn from 'classnames';

export const WebSocket = ({ showIconStatus }) => {
  const dispatch = useDispatch();
  const { socket } = useSelector((state) => state.app);

  const websocketUrl = process.env.REACT_APP_API_WS_URL + '/ws/location-status?access_token=' + process.env.REACT_APP_API_TOKEN;

  const {
    readyState,
  } = useWebSocket(websocketUrl, {
    onOpen: () => changeSocketStatus(true, '::Socket Opened'),
    onClose: () => changeSocketStatus(false, '::Socket Closed'),
    onError: () => changeSocketStatus(false, '::Socket Error'),
    shouldReconnect: () => appSettings.socket.reconnect,
    ...(appSettings.socket.attemps && { reconnectAttempts: appSettings.socket.attemps }),
    reconnectInterval: () => appSettings.socket.timer * 1000, //60 seconds interval between each attemp
    onMessage: (e) => handleTrigger(e)
  })

  const connectionStatus = {
    [ReadyState.CONNECTING]: '::Connecting Socket',
    [ReadyState.OPEN]: '::Socket Opened',
    [ReadyState.CLOSING]: '::Closing Socket',
    [ReadyState.CLOSED]: '::Socket Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  const changeSocketStatus = (active, status) => {
    dispatch(updateSocketStatus(active))
    console.log(status)
  }

  const handleTrigger = (e) => {

    if (e.data && e.data.charAt(0) === '{') {
      const parsedMessage = JSON.parse(e.data);

      console.log(parsedMessage?.action)

      switch (parsedMessage?.action) {
        case 'case_1':

          break;

        default:
          console.log(connectionStatus)
      }
    }
  }

  return (
    showIconStatus &&
    <div
      className={cn(
        'rounded-full h-[30px] w-[30px] animate-pulse',
        {
          'bg-red-500': !socket.active,
          'bg-green-500': socket.active,
        }
      )}
    />
  )

};