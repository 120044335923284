import React from 'react';
//Settings
import { appSettings } from 'config/app';
//Layouts
import Horizontal from './Horizontal';
import Vertical from './Vertical';

const Layout = ({ children }) => {

  const getLayout = () => {
    switch (appSettings.layout) {
      case 'horizontal':
        return <Horizontal children={children} />
      case 'vertical':
        return <Vertical children={children} />
      default:
        return <Horizontal children={children} />
    }


  }

  return getLayout()

}

export default Layout