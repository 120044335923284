export const appSettings = {
  name: 'Decathlon Delivery',
  description: "",
  layout: "vertical",
  logo: {
    url: "/logo.svg",
    alt: "decathlon-log",
    href: "/",
    width: 300,
    height: 100,
  },
  logoMobile: {
    url: "",
    alt: "",
    href: "/",
    width: 512,
    height: 512,
  },
  defaultLanguage: "pt",
  author: {
    name: "Purple Profile",
    websiteUrl: "https://purpleprofile.pt",
    address: "",
  },
  socket: {
    active: false,
    reconnect: true,
    attemps: false,
    timer: 60 //Time in seconds
  },
  numpad: {
    codeReset: 6 //Time in seconds of code reset on numpad
  },
  toast: {
    className: 'bg-white text-4xl',
    success: {
      className: 'bg-white text-4xl',
      style: {
        maxWidth: 1000
      },
      duration: 5000,
    },
    error: {
      className: 'bg-white text-4xl',
      style: {
        maxWidth: 1000
      },
      duration: 5000
    },
  }
}