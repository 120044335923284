import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'helpers/api';
import toast from 'react-hot-toast';
import { t } from 'i18next';
import { updateLoading } from '../app';

// Create an async thunk for fetch FAQs
export const fetchFaqs = createAsyncThunk('faqs/fetchFaqs', async (params, { dispatch }) => {
  try {
    dispatch(updateLoading(true));

    const response = await api.get('api/faqs/list');

    if (!response.data.ResultCode) {
   
      toast.error(t(`errors.${response.data.Code}`));
      dispatch(updateLoading(false));

      return false;
    }

    dispatch(updateLoading(false));

    return response?.data?.Data;

  } catch (error) {
    
    dispatch(updateLoading(false));
    
    throw error;
  }
});

const faqsSlice = createSlice({
  name: 'faqs',
  initialState: {
    list: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFaqs.fulfilled, (state, action) => {
      state.list = action.payload;
    });
  },
});

export default faqsSlice.reducer;
